<template>
  <div>
    <FitbitHero />
    <Features />
    <Highlights1 />
    <Highlights2 />
    <Footer />
  </div>
</template>

<script>
import FitbitHero from '@/components/FitbitHero.vue'
import Features from '@/components/Features.vue'
import Highlights1 from '@/components/Highlights1.vue'
import Highlights2 from '@/components/Highlights2.vue'
import Footer from '@/components/partials/Footer.vue'

export default {
  name: 'Fitbit',
  components: {
    FitbitHero,
    Features,
    Highlights1,
    Highlights2,
    Footer
  },
  head() {
    return {
      // meta: [{ hid: "robots", name: "robots", content: "noindex" }]
    };
  }
}
</script>
