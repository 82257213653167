import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API;


export default {
   
   /**
     * Get the location information for the user based on the public ip address
     */
    async getLocation() {
        try {


            const returnData = { country_code: '', ip: '', blocked: false, block_list: []  }

            // get the IP location from the ipdata.co api
            try {
                const locationData = await axios.get('https://api.ipdata.co?api-key=0551ed4815f0cc7425f016665a5dcf3d5c1874d58cde2df33fa9b10c');
                returnData.country_code = locationData.data.country_code;
                returnData.ip = locationData.data.ip;
            } catch {
                returnData.country_code = returnData.country_code || '';
                returnData.ip = returnData.ip || '';
            }

            
            // get the IP location from the ipinfo.io api
            if (!returnData.country_code) {
                try {
                    const locationData = await axios.get('https://ipinfo.io/json');

                    returnData.country_code = locationData.data.country;
                    returnData.ip = locationData.data.ip;

                } catch {
                    returnData.country_code = returnData.country_code || '';
                    returnData.ip = returnData.ip || '';
                }
            }

            if (!returnData.country_code) {
                try {
                    // get the IP location from the geojs.io api
                    const locationData = await axios.get('https://get.geojs.io/v1/ip/geo.json');

                    returnData.country_code = locationData.data.country_code;
                    returnData.ip = locationData.data.ip;

                } catch {
                    returnData.country_code = returnData.country_code || '';
                    returnData.ip = returnData.ip || '';
                }
            }

            // check the location in the back end to check for geoclocking
            const response = await axios.post('/airdrop/checkGeoBlock/', { country_code: returnData.country_code || '', ip_address: returnData.ip || '' })
            
            // update the response based on the server check
            if (response.data) {
                returnData.blocked = response.data.blocked;
                returnData.block_list = response.data.block_list;
                returnData.country_code = response.data.country_code;
                returnData.ip = response.data.ip_address;
            } else {
                console.log('Error checking backend location data');
            }

            return returnData;   

        } catch (err) {
            return { Error: err.message };
        }
    }
}