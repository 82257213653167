<!--
  Big Market Chart
  - Line chart on init using minutely data
-->
<template>
  <div>
    {{firstLetterUppercase($t('price'))}}: {{price}}
    <div class="market-chart" @mouseleave="priceLive=true" @mouseover="priceLive=false">
      <highcharts
        ref="chart"
        :class="'stock fade-in is-fullwidth chart-' + color"
        :constructor-type="'stockChart'"
        :options="chart.stockOptions"
      />
    </div>
  </div>
</template>

<script>
import Markets from '../services/Markets';

export default {
  name: 'Chart',
  data() {
    return {
      price: 0,
      priceLive: true,
      initialized: false,

      // variable emitted in events for chartEvents.mixin
      chartType: 'market',

      // Grouping the chart and formatting the component
      dateFormat: 'HH:mm',
      color: 'green',
      dayRange: 1,
      groupBy: 'minute',
      units: 5,

      // Boolean flag for updating the chart live
      // - true for minutely data
      // - false for daily historically
      updateChart: true,

      // Highcharts props
      chart: {
        alignTicks: false,
        percentage: 0,
        startValue: 0,
        stockOptions: {
          defs: {
            gradient0: {
              tagName: 'linearGradient',
              id: 'gradient-green',
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
              children: [
                {
                  tagName: 'stop',
                  offset: 0
                },
                {
                  tagName: 'stop',
                  offset: 1
                }
              ]
            },
            gradient1: {
              tagName: 'linearGradient',
              id: 'gradient-red',
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
              children: [
                {
                  tagName: 'stop',
                  offset: 0
                },
                {
                  tagName: 'stop',
                  offset: 1
                }
              ]
            }
          },
          time: {
            useUTC: false
          },
          chart: {
            alignTicks: false,
            animation: false,
            styledMode: true,
            type: 'area',
            backgroundColor: 'rgba(255, 255, 255, 0.0)'
          },
          scrollbar: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          },
          credits: false,
          yAxis: [
            {
              labels: {
                enabled: false
              }
            },
            {
              labels: {
                enabled: false
              },
              title: {
                enabled: false
              },
              top: '65%',
              height: '35%'
            }
          ],
          xAxis: {
            enabled: true,
            type: 'datetime',
            labels: {
              enabled: true
            },
            events: {
              afterSetExtremes: this.changeColor
            }
          },
          navigator: {
            enabled: false
          },
          tooltip: {
            enabled: true,
            formatter: this.formatTooltip,
            valueDecimals: 2,
            // Position date tooltip label so centered, fixed, and doesn't get hidden at edges
            positioner(boxWidth, boxHeight, point) {
              // let xPos = point.plotX + 10 - boxWidth/2; // If want centered position
              let xPos = point.plotX + 10 - boxWidth;
              const yPos = 10;
              // Left Most Boundary
              if (point.plotX < boxWidth - 10) {
                xPos = point.plotX + 10;
              }
              // Right Most Boundary
              // if (point.plotX + boxWidth/2 > this.chart.plotWidth) {
              //   xPos = xPos - boxWidth/2;
              // }
              return { x: xPos, y: yPos };
            },
            shape: 'square'
          },
          series: [
            {
              keys: ['x', 'y'],
              data: [],
              tooltip: {
                valueDecimals: 2
              },
              dataGrouping: {
                approximation: 'close',
                enabled: true,
                units: [['minute', [5]]]
              },
              point: {
                events: {
                  mouseOver: this.hovering
                }
              },
              states: {
                hover: {
                  halo: {
                    size: 7
                  }
                }
              }
            },
            {
              type: 'column',
              name: 'Volume',
              data: [],
              yAxis: 1
            }
          ]
        }
      }
    };
  },
  watch: {
	  priceLive(nv, ov) {
	  	if (!ov && nv) this.price = this.lastLivePrice;
	  }
  },
  async mounted() {
  	  this.priceUpdate();
      try {
	      const result = await Markets.getData('stock', 'fit');
	      const data = [];
	      result.data.forEach(row => {
			data.push([parseInt(row.timestamp), parseFloat(row.close)])
	      });
	      this.price = data[data.length - 1][1];
	      this.lastLivePrice = data[data.length - 1][1];
	      this.initialize(data);
	  } catch (e) {
	    console.log("Error", e)
	  }
  },
  methods: {
    // Formatting the tooltip on the highcharts component
    formatTooltip(e) {
      return (
        '<span style="color:black">' +
        this.$moment(e.chart.hoverPoint.x).format(this.dateFormat) +
        '</span>'
      );
    },
    // On hover event, publish the data for parent component
    hovering(e) {
      const firstPrice =
        this.dayRange !== 1
          ? this.$refs.chart.chart.series[0].processedYData[0]
          : this.$refs.chart.chart.series[0].options.data[0][4];

      this.price = e.target.y;
      this.$emit('updateHover', {
        dayRange: this.dayRange,
        chartType: 'market',
        live: false,
        last: e.target.y,
        first: firstPrice
      });
    },
    // Call change color function to color the chart after the X axis extremes are set
    // Takes first and last point in the chart and calculates change and change_percent
    // Colors the chart based on change - if negative red, else green
    // Publish the data for parent component
    // Updates grouping
    // Zoom the chart again
    changeColor() {
      let firstValue, lastValue;
      if (this.dayRange === 1) {
        firstValue = this.$refs.chart.chart.series[0].options.data[0][4];
        lastValue = this.$refs.chart.chart.series[0].options.data[
          this.$refs.chart.chart.series[0].options.data.length - 1
        ][4];
      } else {
        firstValue = this.$refs.chart.chart.series[0].processedYData[0];
        lastValue = this.$refs.chart.chart.series[0].processedYData[
          this.$refs.chart.chart.series[0].processedYData.length - 1
        ];
      }

      this.$emit('updateChange', {
        chartType: 'market',
        dayRange: this.dayRange,
        first: firstValue
      });
      const change_percent = ((lastValue - firstValue) / firstValue) * 100;
      this.color = change_percent >= 0 ? 'green' : 'red';
      this.$refs.chart.chart.series[0].update({
        dataGrouping: { units: [[this.groupBy, [this.units]]] }
      });
      // Set max and min value to the chart options
      const ex = this.$refs.chart.chart.yAxis[0].getExtremes();
      const min =
        parseFloat(ex.dataMin === ex.dataMax ? ex.dataMin * 0.8 : ex.dataMin) *
        0.999999;
      const max =
        parseFloat(ex.dataMin === ex.dataMax ? ex.dataMax * 1.2 : ex.dataMax) *
        1.000001;
      this.$refs.chart.chart.yAxis[0].setExtremes(min, max);
      // this.hideFirstLast();
      if (this.initialized) this.loading(false);
    },
    initialize(data) {
      if (this.$refs.chart) this.$refs.chart.chart.series[0].setData(data);
    },
    priceUpdate(){
    	this.priceInterval = setInterval(async () => {
		    try {
			    const result = await Markets.getPrice('stock', 'fit');
			    if (this.priceLive) {
			    	this.price = result.data;
			    	this.lastLivePrice = result.data;
			    }
		    } catch (e) {
			    console.log("Error", e)
		    }
	    }, 5000)
    }
  }
};
</script>

<style scoped></style>
