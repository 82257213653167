<template>
  <section class="section feature-section is-medium">
    <div class="container">
      <div class="has-text-centered text-left">
        <h2 class="title is-3">{{$t('features_h2')}}</h2>
      </div>
      <br><br><br>
      <div class="columns left-padding">
        <div class="column is-hidden-mobile" />
        <div class="column is-3">
          <div class="image is-64x64 white-icon">
            <img src="@/assets/img/icon_shorting.png" alt="Shorting icon">
          </div>
          <h3 class="title is-4 mb-10 pt-20">{{$t('shorting')}}</h3>
          <p>
            {{$t('shorting_p')}}
          </p>
        </div>

        <div class="column" />

        <div class="column is-3">
          <div class="image is-64x64 white-icon">
            <img src="@/assets/img/icon_time.png" alt="Clock icon">
          </div>
          <h3 class="title is-4 mb-10 pt-20">{{$t('trading')}}</h3>
          <p>
            {{$t('trading_p')}}
          </p>
        </div>

        <div class="column" />

        <div class="column is-3">
          <div class="image is-64x64 white-icon">
            <img src="@/assets/img/icon_markets.png" alt="Markets icon">
          </div>
          <h3 class="title is-4 mb-10 pt-20">{{$t('every_market')}}</h3>
          <p>
            {{$t('every_market_p')}}
          </p>
        </div>
        <div class="column" />
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "Features"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feature-section {
    margin-top: 100px;
}

.pt-20 {
    padding-top: 20px;
}

@media only screen and (max-width: 1087px) {
  .feature-section {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .left-padding {
    padding-left: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
    .text-left {
        text-align: left !important;
    }
    .left-padding {
        padding-left: 0px;
        margin-left: 0px !important;
        width: 100%;
    }
}
</style>
