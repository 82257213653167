<!--
  Buy/Sell Card Component
-->
<template>
  <div class="terminal">
    <!-- Buy / Sell Form -->
    <div class="terminal-content">
      <div class="trade-tabs">
        <h3
          id="buy"
          class="transition-fast"
          :class="{ 'buy-active': direction === 'long' }"
          @click="direction = 'long'"
        >
          {{firstLetterUppercase($t('buy'))}}
        </h3>
        <span style="width: 60%" />
        <h3
          id="sell"
          class="transition-fast"
          :class="{ 'sell-active': direction === 'short' }"
          @click="direction = 'short'"
        >
          {{firstLetterUppercase($t('sell'))}}
        </h3>
      </div>
      <b-field
        grouped
        class="terminal-groups"
      >
        <p id="amount-text">
          {{$t('amount')}}
        </p>
        <span style="width: 35%" />
        <b-input
          v-model="amount"
          style="width: 40%"
          custom-class="has-text-right"
          placeholder="0.00"
          @focus="input_focused = true"
          @blur="input_focused = false"
        />
      </b-field>
      <b-field grouped class="terminal-groups">
        <div class="align-left-div">
          <p>
            {{$t('market_price')}}
            <span class="align-right-number">
              {{ (price) }}
            </span>
          </p>
        </div>
      </b-field>
      <b-field expanded class="mt-40">
        <a
          :class="{
            'button big-button transition-faster': true,
            'is-buy': direction === 'long',
            'is-sell': direction === 'short'
          }"
          @click="console.log('nothing here')"
        >
          {{ firstLetterUppercase(formatDirection(this.direction)) }}
          {{ $route.params.symbol }}</a>
      </b-field>
      <b-field grouped>
        <!-- By default tell user they can go long/short if their on sell/buy screen. -->
        <div class="align-left-div">
          <hr style="margin: 10px 0;">
          <i18n path="order" tag="p">
            <span place="longOrShort">{{ direction === 'long' ? $t('LONG') : $t('SHORT') }}</span>
            <span place="direction">{{ formatDirection(direction === 'long' ? $t('LONG') : $t('SHORT')) }}</span>
            <span place="symbol">{{ $route.params.symbol }}</span>
          </i18n>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuySellCard',
  data() {
    return {
      input_focused: false,
      direction: 'long',
      price: 0,
      amount: 0,
    };
  },
  methods: {
    formatDirection(direction) {
      return direction === 'long' ? this.$t('buy') : this.$t('sell');
    }
  }
};
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
