<template>
  <section class="section is-medium is-paddingless highlight-section">
    <div class="container">
      <div class="has-text-centered title-section">
        <h2 class="title is-3 is-spaced">{{$t('highlights1_p1')}}</h2>
        <h4 class="subtitle">{{$t('highlights1_p2')}}</h4>
      </div>
      <div class="is-hidden-mobile">
        <br><br><br>
      </div>

      <div class="columns is-vcentered flip-wrapper">
        <div class="column is-one-third is-12-mobile">
          <div class="columns">
            <div class="column is-narrow left-padding">
              <div class="image is-64x64 white-icon">
                <img src="@/assets/img/icon_liquidity.png" :alt="$t('infinite_liquidity') + ' icon'">
              </div>
            </div>
            <div class="column left-padding">
              <h3 class="title is-4 mb-10">{{$t('infinite_liquidity')}}</h3>
              <p> {{$t('infinite_liquidity_p')}} </p>
            </div>
          </div>
          <br>
          <div class="columns">
            <div class="column is-narrow left-padding">
              <div class="image is-64x64 white-icon">
                <img src="@/assets/img/icon_fees.png" :alt="$t('zero_fees') + ' icon'">
              </div>
            </div>
            <div class="column left-padding">
              <h3 class="title is-4 mb-10">{{$t('zero_fees')}}</h3>
              <p> {{$t('zero_fees_p')}} </p>
            </div>
          </div>
          <br>
          <div class="columns">
            <div class="column is-narrow left-padding">
              <div class="image is-64x64 white-icon">
                <img src="@/assets/img/icon_middlemen.png" :alt="$t('no_middleman') + ' icon'">
              </div>
            </div>
            <div class="column left-padding">
              <h3 class="title is-4 mb-10">{{$t('no_middleman')}}</h3>
              <p> {{$t('no_middleman_p')}} </p>
            </div>
          </div>
          <br>
          <div class="columns">
            <div class="column is-narrow left-padding">
              <div class="image is-64x64 white-icon">
                <img src="@/assets/img/icon_fraction.png" :alt="$t('fractional_shares') + ' icon'">
              </div>
            </div>
            <div class="column left-padding">
              <h3 class="title is-4 mb-10">{{$t('fractional_shares')}}</h3>
              <p> {{$t('fractional_shares_p')}} </p>
            </div>
          </div>
        </div>
        <div class="column is-1" />
        <div class="column max-width-image">
          <figure class="image highlight-image is-736">
            <picture>
              <source type="image/webp" srcset="@/assets/img/homepage_screenshot.webp">
              <source type="image/png" srcset="@/assets/img/homepage_screenshot.png">
              <img src="@/assets/img/homepage_screenshot.png" :alt="$t('homepage_img')">
            </picture>
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Highlights1"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.highlight-image {
    background: #F7FAF9;
    box-shadow: 0 10px 20px 0 rgba(182,191,210,0.55);
    border-radius: 5px;
}
.is-736 {
    max-width: 736px;
}
@media only screen and (max-width: 1087px) {
    .highlight-section {
        margin-top: 100px;
    }
}
@media only screen and (max-width: 768px) {
    .highlight-section {
        margin-top: 60px;
    }
    .highlight-image {
        margin-bottom: 50px;
    }
    .left-padding {
        margin-left: 45px;
        width: 85%;
    }

}
@media only screen and (max-width: 500px) {
    .title-section {
        text-align: left !important;
        padding-left: 24px;
    }
    .left-padding {
        margin-left: 35px;
        width: 83%;
        text-align: left !important;
    }
}
</style>
