<template>
  <section class="section is-medium highlight-section pb-40">
    <div class="container">
      <div class="has-text-centered text-left-mobile">
        <h2 class="title is-3 is-spaced is-main">{{$t('highlights2_p1')}}</h2>
        <h4 class="subtitle">{{$t('highlights2_p2')}}</h4>
      </div>

      <br><div class="is-hidden-mobile"><br><br></div>

      <div class="columns is-vcentered is-centered">

        <div v-show="activeTab === 1" class="column is-one-third center-image">
          <figure class="image is-344">
            <picture>
              <source type="image/webp" srcset="@/assets/img/market_trading.webp">
              <source type="image/png" srcset="@/assets/img/market_trading.png">
              <img src="@/assets/img/market_trading.png" :alt="$t('market_trading_img')">
            </picture>
          </figure>
        </div>
        <div v-show="activeTab === 2" class="column is-one-third center-image">
          <figure class="image is-344">
            <picture>
              <source type="image/webp" srcset="@/assets/img/metamask_window.webp">
              <source type="image/png" srcset="@/assets/img/metamask_window.png">
              <img src="@/assets/img/metamask_window.png" :alt="$t('metamask_img')">
            </picture>
          </figure>
        </div>
        <div v-show="activeTab === 3" class="column is-one-third center-image">
          <figure class="image is-344">
            <picture>
              <source type="image/webp" srcset="@/assets/img/transparent_tx.webp">
              <source type="image/png" srcset="@/assets/img/transparent_tx.png">
              <img src="@/assets/img/transparent_tx.png" :alt="$t('transparent_tx_img')">
            </picture>
          </figure>
        </div>

        <div class="column is-1 is-hidden-mobile" />

        <div class="column is-two-fifths">
          <div class="columns">
            <div class="column is-paddingless pre-mobile-max-width is-offset-1-mobile">
              <a @click="activeTab = 1">
                <div :class="{ 'active-tab': activeTab === 1 }">
                  <h3 class="menu-title is-size-4 pb-5"> {{$t('privacy')}} </h3>
                  <p> {{$t('privacy_p')}} </p>
                </div>
              </a>
            </div>
          </div>

          <div class="pb-40" />

          <div class="columns">
            <div class="column is-paddingless pre-mobile-max-width is-offset-1-mobile">
              <a @click="activeTab = 2">
                <div :class="{ 'active-tab': activeTab === 2 }">
                  <h3 class="menu-title is-size-4 pb-5"> {{$t('security')}} </h3>
                  <p> {{$t('security_p')}} </p>
                </div>
              </a>
            </div>
          </div>

          <div class="pb-40" />

          <div class="columns">
            <div class="column is-paddingless pre-mobile-max-width is-offset-1-mobile">
              <a @click="activeTab = 3">
                <div :class="{ 'active-tab': activeTab === 3 }">
                  <h3 class="menu-title is-size-4 pb-5"> {{$t('transparency')}} </h3>
                  <p> {{$t('transparency_p')}}  </p>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "Highlights2",
    data() {
        return {
            activeTab: 1
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-title {
    font-weight: 600;
}
a {
    color: #999999;
    transition-delay: 0s;
    transition-duration: .2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}
a:hover {
    color: #000;
    transition-delay: 0s;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}
.active-tab {
    border-left: 5px solid #00C386;
    color: #000;
    padding-left: 25px;
    margin-left: -30px;
}
.is-344 {
    max-width: 344px;
}
.pb-40 {
    padding-bottom: 40px !important;
}
.pb-5 {
    padding-bottom: 5px;
}
@media only screen and (max-width: 1087px) {
    .highlight-section {
        margin-top: 100px;
    }
}
@media only screen and (max-width: 768px) {
    .highlight-section {
        margin-top: 60px;
    }
    .center-image {
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 500px) {
    .pre-mobile-max-width {
        width: 90%;
    }
    .text-left-mobile {
        text-align: left !important;
    }
}
</style>
